import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BarChart, Bar, Legend, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import * as BS from "react-bootstrap";



const InvByInvestor = (props) => {

  return (
    <BS.Card style={{ marginBottom: 20, marginTop: 20 }}>
      <BS.Card.Header>Most Active Investors</BS.Card.Header>
      <BS.Card.Body>
        <ResponsiveContainer width='100%' aspect={3.0 / 4.4}>
          <BarChart width={400} height={800} layout="vertical" data={props.d} margin={{ top: 20, right: 20, bottom: 5, left: 50 }}>
            <CartesianGrid strokeDasharray="4 4" />
            <XAxis type="number" dataKey={"investments"} />
            <YAxis type="category" dataKey={"name"} />
            <Tooltip cursor={{ fill: 'rgb(1, 123, 254)' }} />
            <Legend />
            <Bar dataKey="investments" barSize={30} fill="rgb(26, 98, 175)" />
          </BarChart>
        </ResponsiveContainer>

      </BS.Card.Body>
    </BS.Card>
  )
}

export default InvByInvestor